@import "./src/assets/scss/main.scss";









.btn-white-border {
    @extend .btn;

    border-radius: 9px;
    border: 1px solid #000;
    font-size: 16px;
    font-family: Montserrat;
    color: #000;
    padding-left: 37px;
    padding-right: 37px;
    padding-top: 13px;
    display: inline-block;
    padding-bottom: 11px;
    font-weight: 500;
    &:hover{
         background: #3F2A2C;
         color: #FFF;
    }
}
.router-link-active{
    
    @extend .btn;
    background: #3F2A2C;
    border-radius: 9px;
    font-size: 16px;
    font-family: Montserrat;
    color: #FFF;
    padding-left: 37px;
    padding-right: 37px;
    padding-top: 11px;
    padding-bottom: 11px;
     border: 1px solid #3F2A2C;
&:hover{
    color:#fff;
}
}
// .mr{
//     margin-right: 270px !important;
// }
